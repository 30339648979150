.col-row {
    /* @apply w-full break-after-column text-left text-navy; */
}
.col-header {
    /* @apply font-bold w-full border-b-2 border-black-700 mb-6 pb-2 text-base md:text-lg; */
}
.main-content {
    /* @apply flex justify-center flex-col py-16; */
}

h1, h2, h3, h4, h5, h6 {
    @apply font-black;
}
h2 {
    @apply text-2xl;
}
h2 img {
    @apply max-h-16 mx-auto;
}
a.boxed {
    @apply inline-block border-solid border-white border p-6 uppercase leading-3 mb-8 text-base transition-all duration-300;
    /* background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff00 1%); */
}
a.boxed:hover {
    @apply text-black bg-white;
    /* background-image: linear-gradient(to bottom, #ffffff 99%, #ffffff00 100%); */
}

p { 
    @apply mb-8 max-w-screen-sm mx-auto text-base;
}
p a {
    @apply uppercase underline;
}

/* pro gamer headshots */
.grid div {
    @apply relative; 
}
.grid div h3 {
    @apply -rotate-90 absolute left-4 -bottom-8 lg:-bottom-12 origin-left text-6xl lg:text-8xl;
    color: #00000000;
    text-stroke: 1px #fff;
    -webkit-text-stroke: 1px #fff;
}

.trail-line:after {
    content: "";
    @apply h-8 w-px bg-white block mx-auto mt-4;
}

ul#menu-footer-menu li {
    @apply inline-block mx-4 hover:underline hover:opacity-50 transition-all duration-300;
}
.quick-links li {
    @apply mb-4;
}
.quick-links li a {
    @apply hover:underline hover:opacity-50 transition-all duration-300;
}
.social-links li {
    @apply inline-block mx-1;
}
.social-links li a {
    @apply rounded-full bg-white block text-black w-8 h-8 leading-8 hover:bg-gold transition-all duration-300;
}
.social-links li a span {
    @apply hidden;
}


/* social icon embeds */
@charset "UTF-8";

@font-face {
  font-family: "socials";
  src:url("assets/socials.eot");
  src:url("assets/socials.eot?#iefix") format("embedded-opentype"),
    url("assets/socials.woff") format("woff"),
    url("assets/socials.ttf") format("truetype"),
    url("assets/socials.svg#socials") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "socials" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "socials" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube-play:before {
  content: "\79";
}
.icon-twitter:before {
  content: "\74";
}
.icon-linkedin:before {
  content: "\6c";
}
.icon-facebook:before {
  content: "\66";
}
.icon-instagram:before {
  content: "\69";
}