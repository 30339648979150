@tailwind base;
@tailwind components;
@tailwind utilities;

html {scroll-behavior: smooth;}
body {
  margin: 0;
  font-family: 'sofia-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;

  @apply bg-black text-white text-center font-light;
}
